.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(242, 242, 242);
  height: 100%;
  flex: 1;
  min-height: 100vh;
}
.welcomeMessageDiv {
  margin-left: 20px;
  align-self: flex-start;
}
.welcomeMessage {
  font-family: "Gilroy-Medium";
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  color: #444444;
  margin-bottom: 0rem;
}

.btnPrimary {
  height: 50px;
  border-radius: 4px;
  width: 140px;
  text-align: center;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 40px;
  cursor: pointer !important;
  border-color: rgb(0, 90, 146);
  background-color: rgb(0, 90, 146);
}
.btnText {
  color: white;
  display: flex;
  justify-content: center;
  margin: auto;
  margin-left: 0px;
  align-items: center;
  /* height: 50px; */
}
