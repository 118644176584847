.textStyleInputEditModal {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  background-color: white;
  padding-left: 20px;
  font-family: Gilroy-Regular;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0;
  color: #444444;
  border: 1px solid #c7c7c7;
}
.inputMask {
  width: 100%;
  border-radius: 4px;
  height: 70px;
  font-size: 50px;
  font-weight: 500;
  letter-spacing: 0;
  font-family: Gilroy-Medium;
  color: #363636;
  text-align: center;
  background-color: white;
  border: 1px solid white;
}
.reservationField {
  width: 100%;
  border-radius: 4px;
  font-size: 50px;
  font-weight: 500;
  letter-spacing: 0;
  font-family: Gilroy-Medium;
  color: #363636;
  text-align: center;
  background-color: white;
}
.inputMask:focus:not(:focus-visible) {
  background: transparent;
}
.reservationField:focus:not(:focus-visible) {
  background: transparent;
}
