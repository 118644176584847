:root {
  --backgroundColor: rgb(242, 242, 242);
  --marginXL: 20px;
  --paddingXL: 20px;
  --paddingXXL: 30px;
}

:focus-visible {
  outline: none;
}
