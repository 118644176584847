.headingWithBeppoLogo {
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  margin-top: 20px;
  margin-bottom: 20px;
}

.headingWithBeppoLogoHome {
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  margin-bottom: 20px;
}
.img {
  margin-left: 20px;
}
.welcomeLabelContainer {
  display: flex;
  flex-direction: row;
}
.hairlineHeading {
  width: 13px;
  height: 4px;
  border-radius: 2.5px;
  background-color: rgb(199, 199, 199);
  align-self: center;
  margin-right: 7px;
}
.welcomeLabel {
  font-family: "Gilroy-Regular";
  font-size: 25px;
  height: 50;
  font-style: normal;
  color: #444444;
}
