.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(242, 242, 242);
  height: 100%;
  flex: 1;
  min-height: 100vh;
}
.fullWidthUserHome {
  width: 90%;
  margin-top: 5%;
  margin-left: 20px;
  margin-right: 20px;
}
.versionBox {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 10px;
}

.inputMask {
  width: 100%;
  border-radius: 4px;
  height: 96px;
  font-size: 50px;
  font-weight: 500;
  letter-spacing: 0;
  font-family: Gilroy-Medium;
  color: #363636;
  text-align: center;
  background-color: white;
  border: 1px solid white;
}
.inputMask:focus:not(:focus-visible) {
  background: transparent;
}
.spaceBetweenInputs {
  margin-bottom: 60px;
}
.licensePlateButtonUserHome {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  align-self: flex-start;
  margin-left: 20px;
}
.userHomeButtonText {
  font-size: 20px;
  align-self: flex-start;
}
.labels {
  display: flex;
  justify-content: flex-end;
  font-family: Gilroy-Medium;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0;
  color: #444444;
}
.enterTextManually {
  margin-top: 20px;
  font-size: 16px;
  margin-bottom: 40px;
  color: #005a92;
}
.licensePlateOuter {
  border-radius: 5.5px;
  background-color: white;
  border: 1px solid rgb(0, 0, 0);
}
.licensePlateMid {
  border-radius: 4.4px;
  border: 1px solid white;
}
.licensePlateInner {
  margin: 1px;
  border-radius: 3px;
  background-color: white;
  border: 1px solid grey;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.imageSwiss {
  height: 35px;
  width: 27px;
  align-self: center;
  margin-left: 10px;
}
.licensePlateInput {
  font-family: NumberplateLLTT-SwitzerlandRd;
  font-size: 60px;
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0;
  color: #000000;
  text-align: center;
}
.imageCanton {
  height: 35px;
  width: 27px;
  align-self: center;
  margin-right: 10px;
}

.inputFullfil {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: inherit;
}
.disabledText {
  display: flex;
  justify-content: center;
  font-family: Gilroy-Medium;
}
