.ticketNextButton {
  margin-top: 25px;
  text-align: center;
  margin-right: 20px;
  margin-left: 20px;
}
.ticketNextButtonDesktop {
  margin-top: 25px;
  text-align: left;
  margin-right: 20px;
}
.buttonNavbar {
  width: 180px;
  height: 61px;
  border-radius: 4px;
  border-color: rgb(0, 90, 146);
  background: rgb(0, 90, 146);
  color: #fff;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}
.buttonNavbar:hover {
  background-color: #014d7c;
}
.buttonNavbarDisabled {
  width: 180px;
  height: 61px;
  border-radius: 4px;
  border-color: rgb(199, 199, 199);
  background: rgb(199, 199, 199);
  color: #fff;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}
.buttonText {
  font-family: "Gilroy-Medium";
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0;
  text-align: center;
  color: white;
}
