.form {
  margin: 0 20px 20px 20px;
}
.inputMask {
  width: 100%;
  border-radius: 4px;
  height: 96px;
  font-size: 50px;
  font-weight: 500;
  letter-spacing: 0;
  font-family: Gilroy-Medium;
  color: #363636;
  text-align: center;
  background-color: white;
  border: 1px solid white;
}
.inputMask:focus:not(:focus-visible) {
  background: transparent;
}