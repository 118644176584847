.headerLine {
  position: absolute;
  width: 10px;
  height: 4px;
  background-color: #c7c7c7;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  top: 35px;
  left: 0px;
}

.modalTitle {
  font-size: 24px;
  padding-bottom: 24px;
}

.message {
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
  justify-content: space-between;
}

.entityDetailLabel {
  font-family: Gilroy-Regular;
}
.buttonPosition {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
