.form {
  margin: 0 20px 20px 20px;
}
.inputMask {
  width: 100%;
  border-radius: 4px;
  height: 96px;
  font-size: 50px;
  font-weight: 500;
  letter-spacing: 0;
  font-family: Gilroy-Medium;
  color: #363636;
  text-align: center;
  background-color: white;
  border: 1px solid white;
}
.inputMask:focus:not(:focus-visible) {
  background: transparent;
}
.licensePlateOuter {
  border-radius: 5.5px;
  background-color: white;
  border: 1px solid rgb(0, 0, 0);
}
.licensePlateMid {
  border-radius: 4.4px;
  background-color: "#000000";
  border: 1px solid white;
}
.licensePlateInner {
  margin: 1px;
  border-radius: 3px;
  background-color: white;
  border: 1px solid grey;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.inputMaskText {
  text-transform: uppercase;
}
.buttonStyle {
  margin-bottom: 20px;
}
