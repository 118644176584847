.modalHeader {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  margin-bottom: 20px;
}
.img {
  margin-left: 20px;
}
.textContainer {
  display: flex;
}
.hairlineHeading {
  width: 13px;
  height: 4px;
  border-radius: 2.5px;
  background-color: rgb(199, 199, 199);
  align-self: center;
  margin-right: 7px;
}
.label {
  font-size: 18px;
}
