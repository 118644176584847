.headerContainerWithLogo {
  display: flex;
  background-color: white;
  justify-content: space-between;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
}
.headerContainerWithLogo__imgContainer {
  margin-left: 25px;
}
